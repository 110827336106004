input, button {
  padding: 4px;
  border-radius: 8px;
  border: 1px solid black;
  background-color: white;
  font-family: 'Lexend Deca';
  /* text-transform: uppercase; */
}

.image {
  height: 600px;
  width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("FlagNormal.png"), url("BackgroundNormal.png");
  background-size: cover;
  position: relative;
}
.image.gold {
  background-image: url("FlagGold.png"), url("BackgroundGold.png");
}
.image.halloween {
  /* background-image: url("FlagBlack.png"), url("https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fwallpapercave.com%2Fwp%2F47MnHmd.jpg&f=1&nofb=1"); */
  background-image: url("FlagBlack.png"), url("BackgroundHalloween.jpg");
}

.numberGrid {
  border: 4px solid #7F7F7F;
  background-color: #faf8fd;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23616161' fill-opacity='0.43'%3E%3Cpath opacity='.5' d='M96 95h4v1h-4v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4h-9v4h-1v-4H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15v-9H0v-1h15V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h9V0h1v15h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9h4v1h-4v9zm-1 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm9-10v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-10 0v-9h-9v9h9zm-9-10h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9zm10 0h9v-9h-9v9z'/%3E%3Cpath d='M6 5V0H5v5H0v1h5v94h1V6h94V5H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.rachel {
  position: absolute;
  bottom: -50px;
  right: -120px;
}

.rachelgold {
  position: absolute;
  bottom: -0px;
  right: -90px;
}

.nick {
  position: absolute;
  bottom: -0px;
  right: -60px;
  height: 400px;
}

.whiteley {
  position: absolute;
  bottom: -0px;
  right: -140px;
  height: 300px;
}

.board {
  background-color: #0040C0;
  display: block;
  border: 8px solid #7F7F7F;
}
.ttt .board {
  margin-top: -110px;
}

.rowWrapper {
  border: 4px solid #538CD5;
}

.row {
  display: flex;
  flex-direction: row;
  /* border: 2px solid #538CD5; */
}

.card {
  border: 2px solid #538CD5;
  /* border-top: 4px solid rgb(45, 70, 104), 2px solid #538CD5; */
  background-color: #8CB3E3;
  color: white;
  height: 55px;
  flex: 1;
  font-size: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* font-weight: 700; */
}
.card.n100 {
  letter-spacing: -2px;
  font-size: 40px;
}
.card.n25,.card.n50,.card.n75,.card.n100 {
  letter-spacing: -2px;
}
.card.full {
  background-color: #0040C0;
  /* border-top: 4px solid rgb(0, 26, 78); */
}
.selectionBox {
  border: 6px solid #538CD5;
  /* background-color: #8CB3E3; */
  background-color: #0040C0;
  color: white;
  height: 50px;
  width: 200px;
  letter-spacing: -2px;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image.gold .board {
  background-color: rgb(170, 169, 89);
  display: block;
  border: 8px solid rgb(113, 105, 56);
}
.image.gold .card {
  border: 2px solid rgb(148, 139, 84);
  background-color: rgb(196, 185, 117);
}
.image.gold .card.full {
  background-color: rgb(113, 105, 56);
  /* border-top: 4px solid rgb(0, 26, 78); */
}
.image.gold .selectionBox {
  border: 6px solid rgb(148, 139, 84);
  background-color: rgb(113, 105, 56);
}

.image.halloween .board {
  background-color: rgb(255, 94, 0);
  display: block;
  border: 8px solid rgb(0, 0, 0);
}
.image.halloween .card {
  border: 2px solid rgb(0, 0, 0);
  background-color: rgb(255, 208, 0);
}
.image.halloween .card.full {
  background-color: rgb(255, 208, 0);
  color: black;
  /* border-top: 4px solid rgb(0, 26, 78); */
}
.image.halloween .selectionBox {
  border: 6px solid rgb(0, 0, 0);
  background-color: rgb(255, 208, 0);
  color: black;

}
.image.halloween .rowWrapper {
  border: 4px solid rgb(0, 0, 0);
}
.rowWrapper {
  border: 4px solid rgb(148, 139, 84);
}